"use client";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { useTranslate } from "@/components/base/providers/TranslateProv";

const Index = () => {
  const translations = {
    ar: {
      faqHeader: "الاسئلة الشائعة",
      faqTitle: "اهم الاسئلة الشائعة",
      faqDescription:
        "اطلعوا على أهم الأسئلة الشائعة للحصول على إجابات وافية حول خدماتنا وكيفية الاستفادة منها بأفضل شكل.",
      questions: [
        {
          question: "ما هو موقع 'سفير الجامعات' وما الهدف منه؟",
          answer:
            "سفير الجامعات - Safir Universities هو دليلك الشامل لعالم الجامعات الخاصة والأهلية وذات الطابع الخاص في مصر! 🎓 يهدف الموقع إلى توفير معلومات دقيقة ومحدثة حول الجامعات المختلفة، التخصصات المتاحة، متطلبات التقديم، الرسوم الدراسية، والمنح الدراسية. نحن هنا لمساعدتك في اتخاذ القرار الصحيح بشأن مستقبلك الأكاديمي من خلال تقديم جميع المعلومات التي تحتاجها في مكان واحد.",
        },
        {
          question: "كيف يمكنني العثور على معلومات حول جامعة معينة على الموقع؟",
          answer:
            "يوفر موقع 'سفير الجامعات' عدة طرق للبحث عن معلومات حول الجامعات. يمكنك استخدام أي من الطرق التالية:\n\nالبحث باستخدام خانة البحث:\n\nاستخدم خاصية البحث الموجودة في أعلى الصفحة لإدخال اسم الجامعة أو نوعها. ستظهر لك قائمة بالنتائج التي تطابق استفسارك.\n\nالفلترة:\n\nاستخدم خاصية الفلترة المتاحة لتضييق نطاق البحث حسب نوع الجامعة (خاصة، أهلية، ذات طابع خاص)، أو حسب الموقع الجغرافي. اختر الفلاتر المناسبة لتصفية النتائج بما يتوافق مع احتياجاتك.\n\nالتصفح عبر الأقسام:\n\nيمكنك أيضًا التصفح من خلال الأقسام المختلفة في الموقع، مثل 'الجامعات الخاصة' أو 'الجامعات الأهلية' أو 'الجامعات ذات الطابع الخاص'. ابحث عن الجامعة التي تهمك في هذه الأقسام لتجد تفاصيلها.\n\nباستخدام هذه الطرق، يمكنك العثور بسهولة على المعلومات التي تحتاجها حول أي جامعة تهمك.",
        },
        {
          question: "هل يمكنني مقارنة بين الجامعات والتخصصات المختلفة؟",
          answer:
            "نعم، يوفر موقع 'سفير الجامعات' عدة طرق لمساعدتك في مقارنة الجامعات والتخصصات المختلفة:\n\nاستخدام خاصية البحث والفلترة:\n\nفي خانة البحث، يمكنك إدخال اسم الجامعة أو نوعها أو التخصص الذي تهتم به. يمكنك أيضًا استخدام الفلاتر لتضييق نطاق البحث حسب نوع الجامعة، اسمها، كليتها، أو تخصصاتها. هذه الخاصية تساعدك في العثور على الجامعات التي تتناسب مع معاييرك ومقارنة بينها.\n\nالتصفح عبر الأقسام:\n\nيمكنك التصفح من خلال الأقسام المختلفة في الموقع، مثل 'الجامعات الخاصة'، 'الجامعات الأهلية'، و'الجامعات ذات الطابع الخاص'. اختر القسم المناسب واستعرض الجامعات والتخصصات المتاحة لتحديد الأنسب لك.\n\nمقارنة مباشرة بين الجامعات:\n\nبعد العثور على الجامعات التي تهمك باستخدام خاصية البحث أو الفلترة، يمكنك زيارة صفحاتها الفردية لمقارنة المعلومات مثل التخصصات المتاحة، الرسوم الدراسية، ومتطلبات التقديم.",
        },
        {
          question: "كيف يمكنني التحقق من تفاصيل التقديم والقبول لكل جامعة؟",
          answer:
            "يوفر موقع 'سفير الجامعات' طريقة سهلة للتحقق من تفاصيل التقديم والقبول. يمكنك اتباع الخطوات التالية:\n\nالبحث عن الجامعة:\n\nاستخدم خانة البحث في الموقع لإدخال اسم الجامعة التي تهمك أو نوعها أو تخصصاتها.\n\nزيارة صفحة الجامعة:\n\nبعد العثور على الجامعة المطلوبة، انتقل إلى صفحتها الخاصة للحصول على تفاصيل دقيقة عن البرامج والتخصصات المتاحة.\n\nاستخدام خانة 'قدم الآن':\n\nبداخل صفحة الجامعة، ستجد خانة 'قدم الآن' التي تتيح لك التقديم مباشرة إلى الجامعة. اضغط على هذه الخانة لتتمكن من ملء نموذج التقديم أو الحصول على رابط التقديم الإلكتروني.\n\nباستخدام خانة 'قدم الآن'، يمكنك تسهيل عملية التقديم مباشرة إلى الجامعة دون الحاجة للبحث عن الروابط الخارجية أو المعلومات الإضافية.",
        },
        {
          question: "هل يحتوي الموقع على معلومات عن الرسوم الدراسية للجامعات؟",
          answer:
            "نعم، يحتوي موقع 'سفير الجامعات' على معلومات مفصلة حول الرسوم الدراسية لكل جامعة. لتجد تفاصيل الرسوم الدراسية، اتبع الخطوات التالية:\n\nالبحث عن الجامعة:\n\nاستخدم خانة البحث للعثور على الجامعة التي تهمك.\n\nزيارة صفحة الجامعة:\n\nبعد العثور على الجامعة المطلوبة، انتقل إلى صفحتها الخاصة على الموقع.\n\nالاطلاع على تفاصيل الرسوم الدراسية:\n\nستجد قسمًا مخصصًا لعرض الرسوم الدراسية لكل برنامج أو تخصص داخل صفحة الجامعة. يمكنك مراجعة هذه المعلومات للحصول على تفاصيل دقيقة حول الرسوم الدراسية التي قد تختلف بناءً على التخصص والبرنامج.\n\nبهذه الطريقة، يمكنك الحصول على جميع المعلومات المتعلقة بالرسوم الدراسية مباشرة من الموقع، مما يسهل عليك مقارنة الخيارات المختلفة واتخاذ القرار الأفضل.",
        },
        {
          question:
            "كيف يمكنني الحصول على المساعدة إذا واجهت مشكلة في استخدام الموقع؟",
          answer:
            "إذا واجهتك أي مشكلة أثناء استخدام موقع 'سفير الجامعات' أو إذا كان لديك استفسار عن الجامعات، يمكنك التواصل معنا مباشرة عبر الرقم <a class='text-primaryColor underline' target='_blank' href='https://wa.me/+2001067035847'>01067035847</a>.\n\nكما يمكنك الاستفادة القصوى من معلومات الموقع عن طريق استخدام 'سكشن الاستشارات' المتاح على الموقع. هذا القسم مخصص لمساعدتك في الحصول على استشارات متعلقة بالتخصصات، الجامعات، وخيارات الدراسة المختلفة.\n\nنحن هنا لدعمك في كل خطوة لضمان حصولك على أفضل تجربة تعليمية.",
        },
        {
          question: "هل يوفر الموقع معلومات عن المنح الدراسية والفرص المالية؟",
          answer:
            "نعم، يوفر موقع 'سفير الجامعات' معلومات شاملة عن المنح الدراسية المتاحة، بالإضافة إلى مقالات متنوعة ومفيدة حول الفرص المالية والتعليمية.\n\nللعثور على هذه المعلومات:\n\nزيارة قسم المقالات:\n\nانتقل إلى 'قسم المقالات' في الموقع، حيث ستجد مقالات تتناول موضوعات مختلفة بما في ذلك المنح الدراسية المتاحة، وكيفية التقديم لها، وشروط الاستفادة منها.\n\nالبحث عن المنح الدراسية:\n\nيمكنك العثور على معلومات محدثة حول المنح الدراسية المتاحة في الجامعات المختلفة من خلال مقالات مفصلة تقدم لك كافة التفاصيل المطلوبة.\n\nبهذا الشكل، يوفر لك الموقع كل ما تحتاجه من معلومات حول المنح الدراسية والفرص المالية لتسهيل عملية البحث واتخاذ القرار الأنسب.",
        },
        {
          question: "كيف يتم تحديث المعلومات على الموقع؟",
          answer:
            "موقع 'سفير الجامعات' يتم تحديثه بشكل تلقائي لضمان أن تكون جميع المعلومات التي تراها محدثة ودقيقة. لست بحاجة إلى تحديث الصفحة بشكل دوري؛ فالموقع يقوم بتحديث المعلومات تلقائيًا فور توفرها.\n\nسواء كنت تبحث عن أحدث الرسوم الدراسية، تفاصيل التقديم، أو المنح الدراسية الجديدة، يمكنك الاطمئنان إلى أنك ستحصل على أحدث البيانات المتاحة بدون أي جهد إضافي من جانبك.",
        },
        {
          question:
            "هل يمكنني الاشتراك في النشرات الإخبارية أو تلقي تحديثات من الموقع؟",
          answer:
            "نعم، يمكنك الاشتراك لتلقي آخر الأخبار والتحديثات من موقع 'سفير الجامعات'. كل ما عليك فعله هو تسجيل بياناتك على الموقع، وستتلقى التحديثات مباشرة على بريدك الإلكتروني أو من خلال الرسائل النصية، لتكون دائمًا على اطلاع بأحدث المعلومات حول الجامعات، التخصصات، والمنح الدراسية.",
        },
        {
          question:
            "هل يمكنني مشاركة معلومات من الموقع على وسائل التواصل الاجتماعي؟",
          answer:
            "نعم، يمكنك بسهولة مشاركة المعلومات المهمة من موقع 'سفير الجامعات' مع أصدقائك ومتابعيك على وسائل التواصل الاجتماعي. في أسفل كل مقال أو صفحة جامعة، ستجد أيقونة المشاركة. اضغط عليها لتتمكن من مشاركة الرابط مباشرة على منصاتك المفضلة.\n\nبهذه الطريقة، يمكنك الاحتفاظ بالمعلومات ومشاركتها مع الآخرين للحصول على الفائدة القصوى.",
        },
      ],
    },
    en: {
      faqHeader: "Frequently Asked Questions",
      faqTitle: "Most Common Questions",
      faqDescription:
        "Check out the most frequently asked questions to get comprehensive answers about our services and how to make the best use of them.",
      questions: [
        {
          question: "What is 'Safir Universities' and what is its purpose?",
          answer:
            "'Safir Universities' is your comprehensive guide to private, public, and specialized universities in Egypt! 🎓 The website aims to provide accurate and up-to-date information about different universities, available programs, application requirements, tuition fees, and scholarships. We are here to help you make the right decision about your academic future by providing all the information you need in one place.",
        },
        {
          question:
            "How can I find information about a specific university on the site?",
          answer:
            "'Safir Universities' offers several ways to search for information about universities. You can use any of the following methods:\n\nSearch box:\n\nUse the search feature at the top of the page to enter the name or type of the university. A list of results matching your query will appear.\n\nFiltering:\n\nUse the available filtering options to narrow your search by university type (private, public, specialized), or by geographic location. Select the appropriate filters to refine the results to match your needs.\n\nBrowsing sections:\n\nYou can also browse through different sections of the site, such as 'Private Universities', 'Public Universities', or 'Specialized Universities'. Look for the university you are interested in within these sections to find its details.\n\nUsing these methods, you can easily find the information you need about any university you are interested in.",
        },
        {
          question: "Can I compare different universities and programs?",
          answer:
            "Yes, 'Safir Universities' provides several ways to help you compare different universities and programs:\n\nUsing search and filtering:\n\nIn the search box, you can enter the name of the university, its type, or the program you are interested in. You can also use filters to narrow down the search by university type, name, faculty, or program. This feature helps you find universities that meet your criteria and compare them.\n\nBrowsing sections:\n\nYou can browse through the site’s sections, such as 'Private Universities', 'Public Universities', and 'Specialized Universities'. Choose the appropriate section and review the universities and programs available to determine which suits you best.\n\nDirect comparison between universities:\n\nAfter finding the universities that interest you using the search or filtering feature, you can visit their individual pages to compare information such as available programs, tuition fees, and application requirements.",
        },
        {
          question:
            "How can I check application and admission details for each university?",
          answer:
            "'Safir Universities' provides an easy way to check application and admission details. Follow these steps:\n\nSearch for the university:\n\nUse the search box on the site to enter the name of the university or its type or programs.\n\nVisit the university’s page:\n\nAfter finding the desired university, go to its specific page on the site.\n\nUse the 'Apply Now' section:\n\nWithin the university’s page, you will find an 'Apply Now' section that allows you to apply directly to the university. Click this section to fill out the application form or get the online application link.\n\nUsing the 'Apply Now' section makes the application process easier, allowing you to apply directly to the university without needing to find external links or additional information.",
        },
        {
          question:
            "Does the site have information about tuition fees for universities?",
          answer:
            "Yes, 'Safir Universities' has detailed information about tuition fees for each university. To find tuition details, follow these steps:\n\nSearch for the university:\n\nUse the search box to find the university you are interested in.\n\nVisit the university’s page:\n\nAfter finding the desired university, go to its specific page on the site.\n\nCheck tuition details:\n\nYou will find a section dedicated to displaying tuition fees for each program or specialization within the university's page. Review this information to get accurate details about tuition fees, which may vary based on the program and specialization.\n\nThis way, you can get all the information related to tuition fees directly from the site, making it easier to compare different options and make the best decision.",
        },
        {
          question:
            "How can I get help if I encounter an issue using the site?",
          answer:
            "If you encounter any issues while using 'Safir Universities' or if you have any inquiries about universities, you can contact us directly at <a class='text-primaryColor underline' target='_blank' href='https://wa.me/+2001067035847'>01067035847</a>.\n\nYou can also make the most of the site's information by using the 'Consultation Section' available on the site. This section is designed to assist you in getting consultations related to specializations, universities, and study options.\n\nWe are here to support you every step of the way to ensure you have the best educational experience.",
        },
        {
          question:
            "Does the site provide information about scholarships and financial opportunities?",
          answer:
            "Yes, 'Safir Universities' provides comprehensive information about available scholarships, as well as various articles on financial and educational opportunities.\n\nTo find this information:\n\nVisit the articles section:\n\nGo to the 'Articles Section' on the site, where you will find articles covering various topics including available scholarships, how to apply for them, and the conditions for benefiting from them.\n\nSearch for scholarships:\n\nYou can find updated information about available scholarships at different universities through detailed articles providing all the necessary details.\n\nThis way, the site provides you with all the information you need about scholarships and financial opportunities to facilitate your search and decision-making.",
        },
        {
          question: "How is information updated on the site?",
          answer:
            "'Safir Universities' is automatically updated to ensure that all the information you see is current and accurate. You do not need to refresh the page regularly; the site updates information automatically as it becomes available.\n\nWhether you are looking for the latest tuition fees, application details, or new scholarships, you can rest assured that you will receive the most current data available without any additional effort on your part.",
        },
        {
          question:
            "Can I subscribe to newsletters or receive updates from the site?",
          answer:
            "Yes, you can subscribe to receive the latest news and updates from 'Safir Universities'. All you need to do is register your details on the site, and you will receive updates directly to your email or via SMS, keeping you informed about the latest information on universities, programs, and scholarships.",
        },
        {
          question: "Can I share information from the site on social media?",
          answer:
            "Yes, you can easily share important information from 'Safir Universities' with your friends and followers on social media. At the bottom of each article or university page, you will find a share icon. Click on it to share the link directly on your preferred platforms.\n\nThis way, you can keep and share information with others to maximize its benefits.",
        },
      ],
    },
  };

  const { locale }: { locale: "ar" | "en" } = useTranslate();

  const t = translations[locale];
  return (
    <div className="bg-[#F8F9FA]">
      <div className="container py-[100px]">
        <p className="text-20 text-primaryColor w-fit mx-auto py-1 px-7 border border-primaryColor rounded-full mb-2">
          {t.faqHeader}
        </p>
        <p className="text-25 text-darkText font-[600] text-center mb-5">
          {t.faqTitle}
        </p>
        <p className="text-20 text-[#6C7278] text-center max-w-[723px] mx-auto mb-10">
          {t.faqDescription}
        </p>
        <Accordion
          type="single"
          collapsible
          className="w-full"
          defaultValue="item-1"
        >
          {t.questions.map((q, index) => (
            <AccordionItem
              key={index}
              value={`item-${index + 1}`}
              className="bg-white rounded-[5px] mb-5 p-5"
            >
              <AccordionTrigger className=" text-[18px] md:text-[20px] text-textDark font-[500]">
                {q.question}
              </AccordionTrigger>
              <AccordionContent className="text-[14px] md:text-[16px] text-[#9F9F9F] leading-7">
                <p dangerouslySetInnerHTML={{ __html: q.answer }}></p>
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default Index;
