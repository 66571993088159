"use client";
import React, { useState } from "react";
import Image from "next/image";
import aboutus from "@/public/images/aboutus.jpg";
import { useTranslate } from "@/components/base/providers/TranslateProv";
import MyLink from "../myLink/MyLink";

const Index = () => {
  const [lang, setLang] = useState("ar");

  const texts = {
    ar: {
      aboutUs: "نبذه عنا",
      title:
        '“سفير الجامعات “: من "Noureldine for Universities" إلى مرجعك الموثوق في التعليم العالي.',
      description:
        "نقدم لك المعلومات الموثوقة لنكون شركاء في تحقيق أحلامك المستقبلية. ترقبوا المفاجآت والتحديثات المثيرة قريباً!",
      beneficiaries: "مستفيد من خدمتنا",
      consultations: "استشارة عن الجامعات",
      experience: "خبره في المجال",
      contactUs: "تواصل معنا",
      nconsultations: "100",
    },
    en: {
      aboutUs: "About Us",
      title:
        '“University Ambassador”: From "Noureldine for Universities" to Your Trusted Higher Education Reference.',
      description:
        "We provide reliable information to be your partners in achieving your future dreams. Stay tuned for exciting surprises and updates soon!",
      beneficiaries: "Beneficiaries of our service",
      consultations: "University consultations",
      experience: "Years of experience",
      contactUs: "Contact Us",
    },
  };

  const { locale }: { locale: "ar" | "en" } = useTranslate();

  const t = texts[locale];
  return (
    <div className="bg-[#F8F9FA]">
      <div className="container py-[100px] grid grid-cols-1 lg:grid-cols-2 gap-10">
        <div className="flex flex-col gap-5">
          <p className="text-primaryColor py-1.5 px-7 rounded-full border border-primaryColor w-fit">
            {t.aboutUs}
          </p>
          <p className="text-[23px] md:text-[33px] text-darkText font-[600]">
            {t.title}
          </p>
          <p className="text-20 text-descriptionText">{t.description}</p>
          <div className="flex items-center justify-between flex-wrap gap-5 max-w-[600px]">
            <div>
              <p className="text-40 font-[700] text-darkText">
                <span className="text-primaryColor">+100K</span>
              </p>
              <p className="text-18 text-descriptionText font-[500]">
                {t.beneficiaries}
              </p>
            </div>
            <div>
              <p className="text-40 font-[700] text-darkText">
                <span className="text-primaryColor">+10K </span>
              </p>
              <p className="text-18 text-descriptionText font-[500]">
                {t.consultations}
              </p>
            </div>
            <div>
              <p className="text-40 font-[700] text-darkText">
                <span className="text-primaryColor">+7Y</span>
              </p>
              <p className="text-18 text-descriptionText font-[500]">
                {t.experience}
              </p>
            </div>
          </div>
          <MyLink
            href="/contactUs"
            className="text-16 text-white flex items-center justify-center gap-3 bg-primaryColor py-2 px-7 rounded-[10px] w-fit"
          >
            {t.contactUs}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.4881 4.43057C15.8026 4.70014 15.839 5.17361 15.5694 5.48811L9.98781 12L15.5694 18.5119C15.839 18.8264 15.8026 19.2999 15.4881 19.5695C15.1736 19.839 14.7001 19.8026 14.4306 19.4881L8.43056 12.4881C8.18981 12.2072 8.18981 11.7928 8.43056 11.5119L14.4306 4.51192C14.7001 4.19743 15.1736 4.161 15.4881 4.43057Z"
                fill="white"
              />
            </svg>
          </MyLink>
        </div>
        <Image src={aboutus} alt="aboutus" />
      </div>
    </div>
  );
};

export default Index;
